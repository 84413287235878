const months = {
  січня: 1,
  лютого: 2,
  березня: 3,
  квітня: 4,
  травня: 5,
  червня: 6,
  липня: 7,
  серпня: 8,
  вересня: 9,
  жовтня: 10,
  листопада: 11,
  грудня: 12,
}

const compare = (a, b) => {
  const comparable = months[a.match(/[а-яієї]{1,}/i)]
  const comparator = months[b.match(/[а-яієї]{1,}/i)]

  if (comparable > comparator) return 1
  if (comparable < comparator) return -1
  return 0
}

// https://stackoverflow.com/a/31102605
const order = (object) => {
  let newObj = {}
  for (const [key, value] of Object.entries(object)) {
    const ordered = Object.keys(value)
      .sort()
      .reduce((obj, key) => {
        obj[key] = value[key]
        return obj
      }, {})
    newObj[key] = ordered
  }

  return newObj
}

export default function organize(lessons) {
  let obj = {}
  let arr = []

  lessons.forEach((lesson) => {
    arr.push(`${lesson.date[1]}/${lesson.date[0]}`)
  })

  arr = Array.from(new Set(arr))
  arr.sort()
  arr.sort(compare)
  arr.forEach((el) => (obj[el] = {}))

  lessons.forEach(({ name, group, date, faculty, denna, type, course, auditorium }) => {
    const [weekday, day, timeString] = date
    const dateKey = `${day}/${weekday}`
    const time = timeString.replaceAll('.', ':').replaceAll(' ', '')
    name = name.trim()

    if (!obj[dateKey][time]) obj[dateKey][time] = []
    if (!obj[dateKey][time][name]) obj[dateKey][time][name] = []

    obj[dateKey][time][name].push({
      group,
      faculty,
      denna,
      type,
      course,
      auditorium,
    })
  })

  return order(obj)
}
