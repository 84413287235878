import { useState } from 'react'
import { Combobox } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

export default function Searchbar({ people, selectedPeople, setSelectedPeople }) {
  const [query, setQuery] = useState('')

  const filteredPeople =
    query === ''
      ? people
      : people.filter((person) => person.toLowerCase().includes(query.toLowerCase()))

  return (
    <Combobox value={selectedPeople} onChange={setSelectedPeople} multiple>
      <>
        <div className="flex w-full gap-1 px-4 py-2 bg-white rounded-2xl">
          <Combobox.Input
            className="w-full overflow-hidden truncate bg-transparent outline-none placeholder:italic placeholder:text-gray-600"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(people) => people.map((person) => person).join(', ')}
            placeholder="Почніть писати прізвище викладача..."
          />

          <Combobox.Button className="items-center">
            <ChevronUpDownIcon className="mr-[0.35rem] h-6 w-6 text-slate-500" aria-hidden="true" />
          </Combobox.Button>
        </div>
        <Combobox.Options className="py-1 overflow-auto bg-white max-h-96 rounded-2xl">
          {filteredPeople.map((person) => (
            <Combobox.Option
              className={({ active }) =>
                `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                  active ? 'bg-blue-500 text-white' : ''
                }`
              }
              key={person}
              value={person}>
              {({ selected, active }) => (
                <>
                  <span className={`block truncate ${selected ? 'font-semibold' : 'font-normal'}`}>
                    {person}
                  </span>
                  {selected ? (
                    <span
                      className={`absolute inset-y-0 left-0 flex items-center pl-3 font-bold ${
                        active ? 'text-white' : 'text-blue-500'
                      }`}>
                      <CheckIcon className="w-5 h-5" />
                    </span>
                  ) : null}
                </>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </>
    </Combobox>
  )
}
