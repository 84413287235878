import React from 'react'
import { QueueListIcon, TableCellsIcon } from '@heroicons/react/24/outline'

export default function Header({ variant, onClick }) {
  return (
    <div className="relative z-50 p-4 px-4 py-3 text-center bg-white shadow-sm md:px-10 lg:px-16 xl:px-20 2xl:px-64">
      <span className="absolute inset-y-0 left-0 flex items-center pl-4 md:px-10 lg:px-16 xl:px-20 2xl:px-64">
        {variant ? (
          <TableCellsIcon
            className="block p-1 my-auto text-blue-600 rounded-md h-9 w-9 bg-slate-200 hover:cursor-pointer hover:bg-slate-300/80 active:bg-slate-200/80"
            onClick={onClick}
          />
        ) : (
          <QueueListIcon
            className="block p-1 my-auto text-blue-600 rounded-md h-9 w-9 bg-slate-200 hover:cursor-pointer hover:bg-slate-300/80 active:bg-slate-200/80"
            onClick={onClick}
          />
        )}
      </span>
      <div className="flex flex-col">
        <div className="flex flex-row justify-center gap-4">
          <img
            className="hidden w-8 h-8 my-auto rounded-full sm:block"
            src={`${process.env.PUBLIC_URL}/circle.jpg`}
            alt="logo"
          />
          <span className="font-semibold text-md md:text-2xl" href="/">
            Європейський Університет
          </span>
          <img
            className="hidden w-8 h-8 my-auto rounded-full sm:block"
            src={`${process.env.PUBLIC_URL}/circle.jpg`}
            alt="logo"
          />
        </div>
        <span className="text-xl font-semibold">Розклад занять для викладачів</span>
      </div>
    </div>
  )
}
