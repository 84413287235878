export default function colorize(type) {
  if (!type) return ''

  switch (type.toLowerCase()) {
    case 'практика':
    case 'пр':
      return 'bg-blue-500 text-blue-100'
    case 'лекція':
    case 'л':
      return 'bg-yellow-400/90 text-orange-900'
    case 'залік':
    case 'пр залік':
    case 'пр\nзалік':
    case 'пр\\залік':
    case 'іспит':
    case 'ккр':
    case 'шк залік':
    case 'multi':
      return 'bg-green-500 text-green-100'
    case '':
      return ''
    default:
      return 'bg-slate-400/50 text-slate-800'
  }
}
