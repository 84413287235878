import React from 'react'
import colorize from '../colorize'
import organize from '../lessons'
import Group from './Group'

export default function Grid({ lessons }) {
  const obj = organize(lessons)

  return (
    <div className="grid w-full grid-cols-1 gap-4 rounded-2xl bg-white p-2 px-4 sm:grid-cols-2 2xl:grid-cols-3">
      {Object.entries(obj).map(([date, times]) => {
        return (
          <div key={date} className="flex flex-col gap-2">
            <span className="inline-flex w-full justify-between border-b-2 border-slate-400 font-semibold uppercase">
              <span className="truncate text-red-600">
                {date.split('/')[0]}
              </span>
              <span className="text-slate-500/80">{date.split('/')[1]}</span>
            </span>
            {Object.entries(times).map(([time, lessons]) => {
              return (
                <div
                  key={`${date}:${time}`}
                  className={`flex gap-2 border-b-2 pb-2 last:border-0 ${
                    Object.keys(lessons).length > 1 ? 'bg-red-200' : ''
                  }`}
                >
                  <span className="font-mono text-slate-600/90">
                    {time.split('-')[0]}
                  </span>
                  <div className="flex w-full flex-col px-1">
                    {Object.entries(lessons).map(([lesson, groups]) => {
                      const audiotiumString = Array.from(
                        new Set(
                          groups.map((group) =>
                            group.auditorium?.replace('google', '')
                          )
                        )
                      ).join(', ')

                      return (
                        <div key={lesson}>
                          <span className="inline-flex w-full justify-between gap-2 px-2 pb-2">
                            <span className="font-semibold">{lesson}</span>
                            <div className="flex gap-1">
                              <span
                                className={`${colorize(
                                  groups[0].type
                                )} my-auto inline-flex h-5 shrink-0 items-center justify-center rounded-full px-2 lowercase`}
                              >
                                {groups[0].type}
                              </span>
                              <span
                                className={`${colorize(
                                  audiotiumString.includes(',')
                                    ? 'multi'
                                    : groups[0].type
                                )} my-auto inline-flex h-5 shrink-0 items-center justify-center rounded-full px-2 lowercase`}
                              >
                                {audiotiumString}
                              </span>
                            </div>
                          </span>
                          <div className="grid max-h-36 grid-cols-2 gap-2 overflow-auto px-2 py-1">
                            {groups.map((value, i) => {
                              return <Group key={i} value={value} />
                            })}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}
