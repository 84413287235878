import React from 'react'

export default function Group({ value }) {
  const match = value?.group?.match(/\d+(?:\/\d)?(?:\s*(?:он|оф))?/i)
  const group = match ? match[0] : value.group || 'помилка'

  if (!match) {
    console.log(value)
  }

  return (
    <a
      href={`https://docs.google.com/spreadsheets/d/${value.course[1]}`}
      target="_blank"
      rel="noreferrer"
      className={`group inline-flex flex-wrap gap-1 bg-slate-200/80 px-2 py-1 text-sm text-slate-600 hover:underline ${
        !value.group ? '!bg-red-500 !text-red-200' : ''
      }`}
    >
      <span className="text-top">{group}</span>
      <span>{value.faculty}</span>
      <span className="truncate">{value.denna ? 'денна' : 'заочна'}</span>
      <span className="hidden group-hover:block">{value.course[0]}</span>
    </a>
  )
}
