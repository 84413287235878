import React from 'react'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon, PlusIcon, MinusIcon } from '@heroicons/react/20/solid'
import colorize from '../colorize'
import filter from '../lessons'
import Group from './Group'

export default function Listing({ lessons }) {
  const obj = filter(lessons)

  return (
    <div className="flex w-full flex-col gap-2 rounded-2xl bg-white p-2 shadow">
      {Object.entries(obj).map(([date, times]) => {
        return (
          <Disclosure key={date} defaultOpen>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-200 px-4 py-2 text-left font-medium text-blue-900 hover:bg-blue-400/80">
                  <span className="inline-flex gap-2">
                    <span>{date.split('/')[0]}</span>
                    <span className="rounded bg-blue-900 px-2 text-blue-100">
                      занять: {Object.keys(times).length}
                    </span>
                  </span>
                  <ChevronDownIcon
                    className={`${
                      open ? 'rotate-180 transform' : ''
                    } my-auto h-5 w-5`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="flex flex-col gap-2 px-4">
                  {Object.entries(times).map(([time, lessons]) => {
                    return Object.entries(lessons).map(([lesson, groups]) => {
                      return (
                        <Disclosure key={`${date}:${time}:${lesson}`}>
                          {({ open }) => (
                            <>
                              <Disclosure.Button
                                className={`flex w-full justify-between rounded-lg ${
                                  Object.keys(lessons).length > 1
                                    ? 'bg-red-200 text-red-800 hover:bg-red-300'
                                    : 'bg-slate-200 text-slate-900 hover:bg-slate-300'
                                } px-4 py-2 text-left font-medium`}
                              >
                                <span className="inline-flex gap-2">
                                  <span className="inline-flex gap-1">
                                    <span>{time.split('-')[0]}</span>
                                    <span className="hidden md:block">-</span>
                                    <span className="hidden md:block">
                                      {time.split('-')[1]}
                                    </span>
                                  </span>
                                  <span>{lesson}</span>
                                  <span
                                    className={`${colorize(
                                      groups[0].type
                                    )} my-auto shrink-0 rounded px-2 lowercase`}
                                  >
                                    {groups[0].type}
                                  </span>
                                  <span
                                    className={`${colorize(
                                      groups[0].type
                                    )} my-auto shrink-0 rounded px-2 lowercase`}
                                  >
                                    {Array.from(
                                      new Set(
                                        groups.map((group) =>
                                          group.auditorium.replace('google', '')
                                        )
                                      )
                                    ).join(', ')}
                                  </span>
                                  <span className="my-auto hidden rounded bg-slate-400 px-2 text-slate-900 sm:block">
                                    груп: {groups.length}
                                  </span>
                                </span>
                                {open ? (
                                  <MinusIcon className="my-auto h-5 w-5 shrink-0" />
                                ) : (
                                  <PlusIcon className="my-auto h-5 w-5 shrink-0" />
                                )}
                              </Disclosure.Button>
                              <Disclosure.Panel>
                                <div className="grid grid-cols-2 gap-2 py-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-7">
                                  {groups.map((value, i) => {
                                    return <Group key={i} value={value} />
                                  })}
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      )
                    })
                  })}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        )
      })}
    </div>
  )
}
