import React from 'react'

export default function Textbox({ error, text, Icon, spin, link }) {
  return (
    <div className={`flex w-full gap-4 rounded-2xl bg-white px-4 py-2 shadow`}>
      {Icon ? (
        <Icon
          className={`my-auto h-6 w-6 shrink-0 ${
            error ? 'animate-bounce text-red-600' : 'text-blue-500'
          } ${spin ? 'animate-spin' : ''}`}
        />
      ) : null}
      <span className="my-auto">
        {text}{' '}
        <a
          className={`${link ? '' : 'hidden'} text-blue-500 underline`}
          target="_blank"
          href={link}
          rel="noreferrer">
          Детальніше
        </a>
      </span>
      <span className="my-auto font-mono">{error}</span>
    </div>
  )
}
