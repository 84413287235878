import { useEffect, useRef, useState } from 'react'
import useFetch from './useFetch'
import Searchbar from './components/Searchbar'
import Listing from './components/Listing'
import Grid from './components/Grid'
import Textbox from './components/Textbox'
import Header from './components/Header'
import ErrorBoundary from './components/ErrorBoundary'
import {
  ExclamationTriangleIcon,
  QuestionMarkCircleIcon,
  ArrowPathIcon,
  CameraIcon,
} from '@heroicons/react/20/solid'
import { toPng } from 'html-to-image'
import Instruction from './components/Instruction'

let parsingErrors, timestamp

function App() {
  const [selectedPeople, setSelectedPeople] = useState([])
  const [lessons, setLessons] = useState([])
  const [listing, setListing] = useState(false)
  const domElement = useRef(null)

  useEffect(() => {
    setLessons([])
    selectedPeople.forEach(async (person) => {
      let request = await fetch(`https://api.shedulem.e-u.edu.ua/lessons?q=${person}`).then((r) => r.json())

      setLessons((l) => [...l, ...request.lessons])
      parsingErrors = request.parsingErrors
      timestamp = request.timestamp
    })
  }, [selectedPeople])

  const { data, error } = useFetch('https://api.shedulem.e-u.edu.ua/index')

  return (
    <>
      <Header
        variant={listing}
        onClick={() => {
          setListing((l) => !l)
        }}
      />
      <div className="mx-auto flex w-full flex-col gap-2 p-4 xl:w-[70%]">
        {data ? (
          <Searchbar
            people={data}
            selectedPeople={selectedPeople}
            setSelectedPeople={setSelectedPeople}
          />
        ) : error ? (
          <Textbox text={'Виникла помилка:'} error={error.message} />
        ) : (
          <Textbox text={'Завантаження...'} />
        )}
        {lessons.length ? (
          <>
            {parsingErrors ? (
              <Textbox
                text={`Під час завантаження інформації виникло ${parsingErrors} помилок. Можливо порушено шаблон. Ці дані не було додано до списку занять.`}
                error={true}
                Icon={ExclamationTriangleIcon}
                link={'https://api.shedulem.e-u.edu.ua/errors'}
              />
            ) : null}
            <div className="flex flex-col gap-2 sm:flex-row">
              <Textbox
                text={`Останнє оновлення даних: ${new Date(timestamp).toLocaleString('uk-UA')}`}
                Icon={QuestionMarkCircleIcon}
              />
              <div className="flex justify-center gap-2">
                <div
                  className="flex justify-around w-full gap-4 p-1 bg-white shadow group rounded-2xl hover:cursor-pointer hover:bg-slate-100"
                  onClick={() => {
                    fetch('https://api.shedulem.e-u.edu.ua/update')
                    setTimeout(window.location.reload.bind(window.location), 4000)
                  }}>
                  <span className="block my-auto font-semibold sm:hidden">Оновити дані</span>
                  <ArrowPathIcon className="py-1 my-auto text-blue-500 rounded-md h-9 w-9 group-hover:text-blue-400 group-active:text-blue-300" />
                </div>
                <div
                  className="flex justify-around w-full gap-4 p-1 bg-white shadow group rounded-2xl hover:cursor-pointer hover:bg-slate-100"
                  onClick={async () => {
                    const dataUrl = await toPng(domElement.current)

                    const link = document.createElement('a')
                    link.download = 'table.png'
                    link.href = dataUrl
                    link.click()
                  }}>
                  <span className="block my-auto font-semibold sm:hidden">Завантажити</span>
                  <CameraIcon className="py-1 my-auto text-blue-500 rounded-md h-9 w-9 group-hover:text-blue-400 group-active:text-blue-300" />
                </div>
              </div>
            </div>
            <div id="listing" ref={domElement}>
              {listing ? (
                <ErrorBoundary>
                  <Listing lessons={lessons} />
                </ErrorBoundary>
              ) : (
                <ErrorBoundary>
                  <Grid lessons={lessons} />
                </ErrorBoundary>
              )}
            </div>
          </>
        ) : (
          <Instruction />
        )}
      </div>
    </>
  )
}

export default App
